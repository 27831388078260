export const Images = {
  1: require("./1.png").default,
  2: require("./2.png").default,
  3: require("./3.png").default,
  5: require("./5.png").default,
  6: require("./6.png").default,
  7: require("./7.png").default,
  8: require("./8.png").default,
  9: require("./9.png").default,
  10: require("./10.png").default,
  11: require("./11.png").default,
  12: require("./12.png").default,
  13: require("./13.png").default,
  14: require("./14.png").default,
  15: require("./15.png").default,
  16: require("./16.png").default,
  17: require("./17.png").default,
  18: require("./18.png").default,
  19: require("./19.png").default,
  clients1: require("./clients1.jpg").default,
  leftarrow: require("./leftarrow.png").default,
  rightarrow: require("./rightarrow.png").default,
};
